
    // @ is an alias to /src
    import VuePrismEditor from "vue-prism-editor";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            VuePrismEditor,
        },
    })

    // *****************************************************************************************************************
    export default class DevVisualStusioCodeExtensionsAndSettings extends Vue {
        // tslint:disable
        private readonly chrome_extensions = {
            headers: [
                {
                    text:       "Extension",
                    align:      "left",
                    value:      "name",
                },
                { 
                    text:       "Description",
                    align:      "left",
                    value:      "description",
                    sortable:   false,
                }
            ],

            items: [
                {
                    name:           "ColorZilla",
                    url:            "https://chrome.google.com/webstore/detail/colorzilla/bhlhnicpbhignbdhedgjhgdocnmhomnp",
                    description:    "Advanced Eyedropper, Color Picker, Gradient Generator and other colorful goodies.",
                },
                {
                    name:           "Vue.js devtools",
                    url:            "https://chrome.google.com/webstore/detail/vuejs-devtools/nhdogjmejiglipccpnnnanhbledajbpd",
                    description:    "Chrome and Firefox DevTools extension for debugging Vue.js applications. Beta channel for Vue.js devtools.",
                },
                
            ]
        };

        private readonly vs_extensions = {
            headers: [
                {
                    text:       "Extension",
                    align:      "left",
                    value:      "name",
                },
                { 
                    text:       "Description",
                    align:      "left",
                    value:      "description",
                    sortable:   false,
                }
            ],

            items: [
                {
                    name:           "AWS CLI Configure",
                    url:            "https://marketplace.visualstudio.com/items?itemName=mark-tucker.aws-cli-configure",
                    description:    "Quickly access the AWS CLI config & credentials files and set any named profile as the [default] profile",
                },
                {
                    name:           "Babel JavaScript",
                    url:            "https://marketplace.visualstudio.com/items?itemName=mgmcdermott.vscode-language-babel",
                    description:    "Syntax highlighting for today's JavaScript, ported from gandm's language-babel for Atom.",
                },
                {
                    name:           "Better Comments",
                    url:            "https://marketplace.visualstudio.com/items?itemName=aaron-bond.better-comments",
                    description:    "Improve your code commenting by annotating with alert, informational, TODOs, and more!",
                },
                {
                    name:           "Bracket Pair Colorizer",
                    url:            "https://marketplace.visualstudio.com/items?itemName=CoenraadS.bracket-pair-colorizer",
                    description:    "A customizable extension for colorizing matching brackets.",
                },
                {
                    name:           "C/C++",
                    url:            "https://marketplace.visualstudio.com/items?itemName=ms-vscode.cpptools",
                    description:    "C/C++ IntelliSense, debugging, and code browsing.",
                },
                {
                    name:           "C#",
                    url:            "https://marketplace.visualstudio.com/items?itemName=ms-vscode.csharp",
                    description:    "C# (powered by OmniSharp).",
                },
                {
                    name:           "C# Extensions",
                    url:            "https://marketplace.visualstudio.com/items?itemName=jchannon.csharpextensions",
                    description:    "C# Extensions.",
                },
                {
                    name:           "C++ Intellisense",
                    url:            "https://marketplace.visualstudio.com/items?itemName=austin.code-gnu-global",
                    description:    "C/C++ Intellisense with the help of GNU Global tags.",
                },
                {
                    name:           "Debugger for Chrome",
                    url:            "https://marketplace.visualstudio.com/items?itemName=msjsdiag.debugger-for-chrome",
                    description:    "Debug your JavaScript code in the Chrome browser, or any other target that supports the Chrome Debugger protocol.",
                },
                {
                    name:           "ESLint",
                    url:            "https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint",
                    description:    "Integrates ESLint JavaScript into VS Code..",
                },
                {
                    name:           "gitignore",
                    url:            "https://marketplace.visualstudio.com/items?itemName=codezombiech.gitignore",
                    description:    "Language support for .gitignore files. Lets you pull .gitignore files from the https://github.com/github/gitignore repository.",
                },
                {
                    name:           "Highlight Matching Tag",
                    url:            "https://marketplace.visualstudio.com/items?itemName=vincaslt.highlight-matching-tag",
                    description:    "Highlights matching closing or opening tags.",
                },
                {
                    name:           "Import Cost",
                    url:            "https://marketplace.visualstudio.com/items?itemName=wix.vscode-import-cost",
                    description:    "Display import/require package size in the editor.",
                },
                {
                    name:           "indent-rainbow",
                    url:            "https://marketplace.visualstudio.com/items?itemName=oderwat.indent-rainbow",
                    description:    "Makes indentation easier to read.",
                },
                {
                    name:           "MagicPython",
                    url:            "https://marketplace.visualstudio.com/items?itemName=magicstack.MagicPython",
                    description:    "Syntax highlighter for cutting edge Python.",
                },
                {
                    name:           "markdownlint",
                    url:            "https://marketplace.visualstudio.com/items?itemName=DavidAnson.vscode-markdownlint",
                    description:    "Markdown linting and style checking.",
                },
                {
                    name:           "Material Theme",
                    url:            "https://marketplace.visualstudio.com/items?itemName=Equinusocio.vsc-material-theme",
                    description:    "The most epic theme now.",
                },
                {
                    name:           "npm Intellisense",
                    url:            "https://marketplace.visualstudio.com/items?itemName=christian-kohler.npm-intellisense",
                    description:    "Autocompletes npm modules in import statements.",
                },
                {
                    name:           "Path Intellisense",
                    url:            "https://marketplace.visualstudio.com/items?itemName=christian-kohler.path-intellisense",
                    description:    "Autocompletes filenames",
                },
                {
                    name:           "PowerShell",
                    url:            "https://marketplace.visualstudio.com/items?itemName=ms-vscode.PowerShell",
                    description:    "Develop PowerShell scripts.",
                },
                {
                    name:           "Prettier",
                    url:            "https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode",
                    description:    "Prettier is an opinionated code formatter. It enforces a consistent style by parsing your code and re-printing it with its own rules that take the maximum line length into account, wrapping code when necessary.",
                },
                {
                    name:           "puglint",
                    url:            "https://marketplace.visualstudio.com/items?itemName=mrmlnc.vscode-puglint",
                    description:    "Linter and style checker for Pug (formerly Jade).",
                },
                {
                    name:           "Python",
                    url:            "https://marketplace.visualstudio.com/items?itemName=ms-python.python",
                    description:    "Linting, Debugging (multi-threaded, remote), Intellisense, code formatting, refactoring, unit tests, snippets, and more.",
                },
                {
                    name:           "Python for VSCode",
                    url:            "https://marketplace.visualstudio.com/items?itemName=tht13.python",
                    description:    "Python language extension.",
                },
                {
                    name:           "REST Client",
                    url:            "https://marketplace.visualstudio.com/items?itemName=humao.rest-client",
                    description:    "REST Client.",
                },
                {
                    name:           "TODO Highlight",
                    url:            "https://marketplace.visualstudio.com/items?itemName=wayou.vscode-todo-highlight",
                    description:    "Highlight TODOs, FIXMEs, and any keywords, annotations...",
                },
                {
                    name:           "Todo Tree",
                    url:            "https://marketplace.visualstudio.com/items?itemName=Gruntfuggly.todo-tree",
                    description:    "Show TODO, FIXME, etc. comment tags in a tree view.",
                },
                {
                    name:           "Toggle Quotes",
                    url:            "https://marketplace.visualstudio.com/items?itemName=BriteSnow.vscode-toggle-quotes",
                    description:    "Toggle cycle \" to ' to `.",
                },
                {
                    name:           "TSLint",
                    url:            "https://marketplace.visualstudio.com/items?itemName=ms-vscode.vscode-typescript-tslint-plugin",
                    description:    "[Prefer ESLint] TSLint support.",
                },
                {
                    name:           "TypeScript Hero",
                    url:            "https://marketplace.visualstudio.com/items?itemName=rbbit.typescript-hero",
                    description:    "Additional toolings for typescript.",
                },
                {
                    name:           "Vetur",
                    url:            "https://marketplace.visualstudio.com/items?itemName=octref.vetur",
                    description:    "Vue tooling.",
                },
                {
                    name:           "yarn",
                    url:            "https://marketplace.visualstudio.com/items?itemName=gamunu.vscode-yarn",
                    description:    "yarn commands.",
                },
            ],
        };
        // tslint:enable

        // Data function
        private data() {
            return {
                systemsIconPathAndName:
                    require("@/assets/images/icon_systems.png"),

                visualStudioCodeSettings:
                    '{' + '\n' +
                    '    "editor.minimap.showSlider": "always",' + '\n' +
                    '    "editor.fontSize": 16,' + '\n' +
                    '    "editor.rulers": [120],' + '\n' +
                    '    "editor.renderIndentGuides": false,' + '\n' +
                    '\n' +
                    '    "workbench.colorTheme": "Visual Studio Dark",' + '\n' +
                    '    "workbench.colorCustomizations": {' + '\n' +
                    '        "activityBar.activeBorder": "#1286d3",' + '\n' +
                    '        "activityBar.activeBackground": "#094771"' + '\n' +
                    '    },' + '\n' +
                    '\n' +
                    '    "explorer.confirmDelete": false,' + '\n' +
                    '    "explorer.confirmDragAndDrop": false,' + '\n' +
                    '\n' +
                    '    // https://marketplace.visualstudio.com/items?itemName=mrmlnc.vscode-puglint' + '\n' +
                    '    "puglint.enable": true,' + '\n' +
                    '\n' +
                    '    "terminal.integrated.rendererType": "dom",' + '\n' +
                    '\n' +
                    '    "window.zoomLevel": 1,' + '\n' +
                    '    "html.format.endWithNewline": true,' + '\n' +
                    '    "diffEditor.renderSideBySide": false,' + '\n' +
                    '    "javascript.updateImportsOnFileMove.enabled": "always",' + '\n' +
                    '    "todo-tree.highlights.enabled": true,' + '\n' +
                    '    "[typescript]": {' + '\n' +
                    '    },' + '\n' +
                    '\n' +
                    '    "[vue]": {' + '\n' +
                    '        "editor.defaultFormatter": "octref.vetur"' + '\n' +
                    '    },' + '\n' +
                    '\n' +
                    '    "tslint.alwaysShowRuleFailuresAsWarnings": false,' + '\n' +
                    '    "tslint.packageManager": "yarn",' + '\n' +
                    '    "tslint.configFile": "C:\\\\Dev\\\\UnusualReality\\\\tsconfig.json",' + '\n' +
                    '}' + '\n',
            };
        }
    }
